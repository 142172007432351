<template>
  <div id="app">
    <Header />
    <MainContent v-show="this.visible" @show-choosen="openDetails"/>
    <Details :id="id" :picked="picked" v-if="!this.visible" />
  </div>
</template>

<script>
// import { showFilmDetails } from "./mixins/fetch";
export default {
  // mixins: [showFilmDetails],
  name: "App",
  data() {
    return {
      visible: true,
      id: null,
      picked: null,
      baseFilms: []      
    }
  },
  components: {
    Header: () => import("./components/Header"),
    MainContent: () => import("./components/MainContent"),
    Details: () => import("./components/Details"),
  },
  methods: {
    openDetails(param) {
      this.id = param.id;
      this.picked = param.picked;
      this.visible = !this.visible;
    },
  },
};
</script>

<style lang="scss">
.wrapper {
  & > div {
    padding: 0 0.5rem;
    width: 100%;
  }
}
</style>
